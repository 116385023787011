export default defineNuxtRouteMiddleware(async (to) => {
  if (!import.meta.client) return
  const { $i18n } = useNuxtApp()
  const { locale } = $i18n
  const isLoggedInForUtils = import.meta.client ? sessionStorage.getItem('utilsToken') : false
  const loginPage = `/${locale.value}/utils`

  // Redirect to /utils if not logged in
  if (!isLoggedInForUtils && to.path !== loginPage) {
    return navigateTo(`/${locale.value}/utils`)
  }

  // If logged in and landing on /utils go to Exponea
  if (to.path === loginPage && isLoggedInForUtils) {
    return navigateTo(`/${locale.value}/utils/exponea`)
  }
})
